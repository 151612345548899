@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@500;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@import url("./reset.css");

:root {
  --bg-app: rgb(255, 255, 255);
  --bg-cell: rgb(244, 244, 244);
  --bg-cell-non-editable: rgb(255, 255, 255);
  --cell-size: 8vw;
  --cell-gap: 4px;
  --cell-border-radius: 4px;
  --cell-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.07),
    0 4px 4px rgba(0, 0, 0, 0.05);
  --cell-selected-shadow: 0 0 2px rgba(0, 0, 0, 0.105),
    0 0 8px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.09),
    0 0 32px rgba(0, 0, 0, 0.085), 0 0 40px rgba(0, 0, 0, 0.08),
    0 0 48px rgba(0, 0, 0, 0.07);
  --inset-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08),
    inset 0 2px 2px rgba(0, 0, 0, 0.07), inset 0 4px 4px rgba(0, 0, 0, 0.05);
  --button-padding: 8px 12px;
  --button-color: rgb(108, 97, 88);
  --button-background: transparent;
  --button-border: 2px solid rgb(108, 97, 88);
  --action-bar-gap: 16px;
  --action-bar-margin-bottom: 24px;
}

.material-symbols-sharp {
  font-variation-settings:
    "FILL" 1,
    "wght" 400,
    "GRAD" 0,
    "opsz" 20;
}

.App {
  font-family: "Gabarito", sans-serif;
  font-weight: 500;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: top;
  gap: 2vh;
  padding-top: 5vh;
  align-items: center;
  height: 100vh;
  background: var(--bg-app);
  color: #90867d;
  text-transform: uppercase;
}

.heading {
  font-family: "Gabarito", sans-serif;
  font-weight: 700;
}
.heading--one {
  font-size: 2em;
  margin-bottom: 24px;
  letter-spacing: 1px;
}
.heading--one {
  font-size: 1.8em;
  margin-bottom: 24px;
}

.action-bar {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: var(--action-bar-gap);
}

.button {
  font-family: "Gabarito", sans-serif;
  color: var(--button-color);
  padding: var(--button-padding);
  border-radius: 500px;
  background: var(--button-background);
  cursor: pointer;
  transition: all 0.1s ease-in;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.1em;
}
.button:hover {
  transform: scale(1.1);
}
.button--color {
  width: 42px;
  height: 42px;
  padding: 12px;
  color: transparent;
  box-shadow: var(--cell-shadow);
  position: relative;
  -webkit-tap-highlight-color: transparent;
  display: inline;
  margin-right: 8px;
  margin-bottom: 8px;
}
.button--color:last-of-type {
  margin-right: 0;
}
/* @media (min-width: 380px) {
  .button--color {
    width: 9vw;
    height: 9vw;
  }
}
@media (min-width: 590px) {
  .button--color {
    width: 8vw;
    height: 8vw;
  }
}
@media (min-width: 720px) {
  .button--color {
    width: 6vw;
    height: 6vw;
  }
}
@media (min-width: 950px) {
  .button--color {
    width: 50px;
    height: 50px;
  }
} */
.button--color:hover {
  transform: scale(1.1);
  z-index: 100;
}
.button--color:disabled {
  transform: scale(0.75);
  cursor: not-allowed;
}
.button--color:disabled:hover {
  transform: scale(0.75);
  box-shadow: var(--cell-shadow);
}
.button--action,
.button--close,
.button--debug {
  font-size: 0.8em;
  letter-spacing: 1px;
  font-weight: 500;
  border: var(--button-border);
  background: var(--button-background);
}

@media (min-width: 520px) {
  .button--action {
    font-size: 1em;
    letter-spacing: 0.5px;
  }
}
@media (min-width: 950px) {
  .button--action {
    font-size: 1.1em;
    letter-spacing: 0.2px;
  }
}

.action-buttons {
  display: flex;
  gap: 8px;
}
.color-buttons {
  /* display: flex;
  align-items: center;
  gap: 4px;
  height: 100%;
  max-height: 300px; */
  padding: 5vh 24px;
}

.grid {
  display: grid;
  gap: var(--cell-gap);
}

.grid > :nth-child(3n) {
  margin-bottom: 8px;
}
.grid > :last-child {
  margin-bottom: 0;
}

.row {
  display: flex;
  gap: var(--cell-gap);
}

.row > :nth-child(3n) {
  margin-right: 8px;
}
.row > :last-child {
  margin-right: 0;
}

.cell {
  cursor: pointer;
  background: var(--bg-cell);
  height: 9vw;
  width: 9vw;
  border-radius: var(--cell-border-radius);
  color: transparent;
  box-shadow: var(--cell-shadow);
  transition: all 0.1s ease-in;
  border: 0.5px solid lightgrey;
  -webkit-tap-highlight-color: transparent;
}
.cell:hover {
  z-index: 100;
}
@media (min-width: 720px) {
  .cell {
    height: 7vw;
    width: 7vw;
  }
}
@media (min-width: 950px) {
  .cell {
    height: 60px;
    width: 60px;
  }
}
.cell--empty {
  background: white;
  box-shadow: none;
  border: 1px solid #eae3db;
}

.cell--non-editable {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: not-allowed;
}
.cell--editable:hover {
  transform: scale(1.1);
}
.cell--non-editable::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: var(--bg-cell-non-editable);
  border-radius: 50%;
  position: absolute;
  right: 4px;
  bottom: 4px;
  box-shadow: var(--inset-shadow);
  transition: all 0.1s ease-in;
}
@media (min-width: 430px) {
  .cell--non-editable::after {
    width: 6px;
    height: 6px;
    right: 4px;
    bottom: 4px;
  }
}
@media (min-width: 520px) {
  .cell--non-editable::after {
    width: 7px;
    height: 7px;
    right: 5px;
    bottom: 5px;
  }
}
@media (min-width: 720px) {
  .cell--non-editable::after {
    width: 8px;
    height: 8px;
    right: 6px;
    bottom: 6px;
  }
}
@media (min-width: 950px) {
  .cell--non-editable::after {
  }
}
.cell--selected {
  box-shadow: var(--cell-selected-shadow);
  z-index: 100;
  transform: scale(1.2);
}
.cell--selected:hover {
  transform: scale(1.2);
  z-index: 100;
}

.cell--error {
  outline: 2px solid red;
}

/* Modal */
.full-screen-dialog-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  background-color: rgb(229, 227, 223);
  background-size: 28px 28px;
}

.full-screen-dialog-content {
  background: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 50px;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  border-radius: 4px;
}
@media (min-width: 480px) {
  .full-screen-dialog-content {
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.11),
      0 2px 2px rgba(0, 0, 0, 0.105),
      0 4px 4px rgba(0, 0, 0, 0.1),
      0 8px 8px rgba(0, 0, 0, 0.095),
      0 16px 16px rgba(0, 0, 0, 0.09),
      0 32px 32px rgba(0, 0, 0, 0.08);
    padding: 50px;
    width: 90vw;
    max-width: 460px;
    height: 500px;
  }
}

.success-image {
  width: 100px;
  height: 100px;
  margin-bottom: 56px;
}

.button--close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.timer {
  font-family: "Gabarito", sans-serif;
  color: var(--button-color);
  padding: var(--button-padding);
  border-radius: 500px;
  background: #eae3db;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.1em;
  width: 110px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.button--pause {
  display: flex;
  align-items: center;
  justify-content: center;
}
